import {createApp} from 'vue';
import router from '@/router.js';
import store from '@/store/index';
import axios from '@/services/axios-instance'
import VueAxios from 'vue-axios';

import App from './App.vue';
import "@/assets/style/main.less";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.mount('#app');