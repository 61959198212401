<template>
	<form class="form">
		<div class="form__input">
			<base-input
					v-model="email"
					:type="'email'"
					label="Email"
					:error-message="emailError"
					:prepend-icon="'mail'"
			/>
		</div>
		<div class="form__input">
			<base-input
					v-model="password"
					:type="passwordHidden ? 'password' : 'text'"
					label="Password"
					:error-message="passwordError"
					:prepend-icon="'lock'"
					:append-icon="passwordHidden ? 'eye' : 'eye-slash'"
					@append="() => (passwordHidden = !passwordHidden)"
			/>
		</div>
		<div class="form__submit">
			<BaseButton @click="submit">Login</BaseButton>
		</div>
	</form>
</template>

<script>
  import BaseInput from '@/components/BaseInput.vue';
  import BaseButton from '@/components/BaseButton.vue';

  export default {
    name: 'AuthForm',


    components: {
      BaseInput,
      BaseButton,
    },


    data() {
      return {
        email: '',
        password: '',
        passwordHidden: true,
        emailError: '',
        passwordError: '',
        validationError: false,
      }
    },


    methods: {
      resetErrors() {
        this.emailError = '';
        this.passwordError = '';
      },
      checkForm() {
        const emailStatus = this.checkEmail();
        const passwordStatus = this.checkPassword();
        return (emailStatus && passwordStatus)
      },
      checkEmail() {
        if (this.email.length === 0) {
          this.emailError = 'Required field, please fill it';
          return false
        } else {
          return true
        }
      },
      checkPassword() {
        if (this.password.length === 0) {
          this.passwordError = 'Required field, please fill it';
          return false
        } else {
          return true
        }
      },
      async submit () {
        this.resetErrors();
        const checkResult = this.checkForm();
        if (checkResult) {
          try {
            await this.$store.dispatch('user/authorize', {
              email: this.email,
              password: this.password
            })
          } catch(error) {
            console.log('auth error!!!!!!!!!!!! ', error.response.data.detail);
            this.emailError = error.response.data.detail;
            this.passwordError = error.response.data.detail;
          } finally {
            this.$router.push('/')
          }
        }
      },
    }
  }
</script>

<style scoped>

</style>