<template>
	<div class="logout" :class="logoutOpen ? 'is-active' : ''">
		<div class="logout__title">Log Out?</div>
		<div class="logout__caption">Are you sure you want to Log Out?</div>
		<div class="logout__button-group">
			<BaseButton secondary @click="logout">Yes, Sure</BaseButton>
			<BaseButton @click="closeLogout">Cancel</BaseButton>
		</div>
	</div>
</template>

<script>
  import { mapState } from 'vuex'
  import BaseButton from "@/components/BaseButton";
  import {OPEN_LOGOUT} from "@/store/modules/mainView/constants";

  export default {
    name: 'LogoutMenu',


    components: {BaseButton},


    computed: {
      ...mapState({
        logoutOpen: state => state.mainView.logout,
      }),
    },


    methods: {
      closeLogout() {
        this.$store.commit(`mainView/${OPEN_LOGOUT}`, false)
      },
      logout() {
        this.$store.dispatch('resetState')
      },
    },
  }
</script>

<style scoped lang="less">
	.logout {
		position: fixed;
		bottom: 0;
		width: 100%;
		border-radius: 24px 24px 0 0;
		overflow: hidden;
		transform: translateY(100%);
		transition: transform .2s ease;
		pointer-events: none;
		z-index: 5;
		padding: 32px 24px 50px;
		background-color: #fff;

		&.is-active {
			transform: translateY(0);
			pointer-events: auto;
		}

		&__title {
			font-family: 'Clash Display';
			font-weight: 700;
			font-size: 24px;
			margin-bottom: 16px;
			text-align: center;
		}

		&__caption {
			font-weight: 500;
			font-size: 14px;
			text-align: center;
		}

		&__button-group {
			margin-top: 40px;
			display: flex;
			justify-content: center;
			gap: 8px;
		}
	}
</style>