<template>
	<div class="board">
		<h2 class="board__title h5">Fans Categories</h2>
		<div class="board__body">
			<div class="fans-categories">
				<div class="fans-categories__item">
					<div class="fan-stat-block">
						<div class="fan-stat-block__row">
							<div class="fan-stat-block__score">A+</div>
							<div class="fan-stat-block__counter">{{ categories.sales_gt_500 }}</div>
						</div>
						<div class="fan-stat-block__amount">purchase amount > <span>$500</span></div>
					</div>
				</div>
				<div class="fans-categories__item">
					<div class="fan-stat-block">
						<div class="fan-stat-block__row">
							<div class="fan-stat-block__score">A</div>
							<div class="fan-stat-block__counter">{{ categories.sales_100_500 }}</div>
						</div>
						<div class="fan-stat-block__amount">purchase amount > <span>$100</span></div>
					</div>
				</div>
				<div class="fans-categories__item">
					<div class="fan-stat-block">
						<div class="fan-stat-block__row">
							<div class="fan-stat-block__score">B</div>
							<div class="fan-stat-block__counter">{{ categories.sales_50_100 }}</div>
						</div>
						<div class="fan-stat-block__amount">purchase amount > <span>$50</span></div>
					</div>
				</div>
				<div class="fans-categories__item">
					<div class="fan-stat-block">
						<div class="fan-stat-block__row">
							<div class="fan-stat-block__score">C</div>
							<div class="fan-stat-block__counter">{{ categories.sales_lt_50 }}</div>
						</div>
						<div class="fan-stat-block__amount">purchase amount &#60; <span>$50</span></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import {mapState} from 'vuex';
  import apiStats from '@/api/stats';

  export default {
    name: 'BoardFansCategories',


    data() {
      return {
        categories: [],
      }
    },


    computed: {
      ...mapState ({
        subProfileId: state => state.user.subProfileId,
      })
    },


    created() {
      this.getFansCategories()
    },


    watch: {
      subProfileId() {
        this.getFansCategories()
      },
    },


    methods: {
      async getFansCategories() {
        try {
          this.categories = await apiStats.getFansCategories()
        } catch (err) {
          console.log(err)
        }
      }
    }
  }
</script>

<style scoped lang="less">
	@import "@/assets/style/variables.less";

	.fans-categories {

		&__item {
			padding: 12px 0 16px;

			&:not(:last-child) {
				border-bottom: 1px solid #EDEEF1;
			}
		}
	}

	.fan-stat-block {

		&__row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 4px;
		}

		&__score {
			width: 48px;
			height: 48px;
			border-radius: 100%;
			font-weight: 700;
			font-size: 18px;
			display: flex;
			align-items: center;
			justify-content: center;

			.fans-categories__item:nth-child(1) & { background-color: #FEF8FC; }
			.fans-categories__item:nth-child(2) & { background-color: #FFFBEF; }
			.fans-categories__item:nth-child(3) & { background-color: #EBF0FB; }
			.fans-categories__item:nth-child(4) & { background-color: #F8F9FE; }
		}

		&__counter {
			font-family: 'Clash Display';
			font-weight: 700;
			font-size: 24px;
		}

		&__amount {
			font-size: 12px;

			& span {
				font-weight: 700;
			}
		}
	}
</style>