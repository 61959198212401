<template>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M2.8864 2.88706C1.66602 4.10745 1.66602 6.07163 1.66602 10C1.66602 13.9284 1.66602 15.8926 2.8864 17.1129C4.10679 18.3333 6.07098 18.3333 9.99935 18.3333C13.9277 18.3333 15.8919 18.3333 17.1123 17.1129C18.3327 15.8926 18.3327 13.9284 18.3327 10C18.3327 6.07163 18.3327 4.10745 17.1123 2.88706C15.8919 1.66667 13.9277 1.66667 9.99935 1.66667C6.07098 1.66667 4.10679 1.66667 2.8864 2.88706ZM14.166 6.875C14.5112 6.875 14.791 7.15483 14.791 7.5V15C14.791 15.3452 14.5112 15.625 14.166 15.625C13.8208 15.625 13.541 15.3452 13.541 15V7.5C13.541 7.15483 13.8208 6.875 14.166 6.875ZM10.6243 10C10.6243 9.65483 10.3445 9.375 9.99935 9.375C9.65417 9.375 9.37435 9.65483 9.37435 10V15C9.37435 15.3452 9.65417 15.625 9.99935 15.625C10.3445 15.625 10.6243 15.3452 10.6243 15V10ZM5.83268 11.875C6.17786 11.875 6.45768 12.1548 6.45768 12.5V15C6.45768 15.3452 6.17786 15.625 5.83268 15.625C5.4875 15.625 5.20768 15.3452 5.20768 15V12.5C5.20768 12.1548 5.4875 11.875 5.83268 11.875Z"/>
</template>

<script>
  export default {
    name: 'IconStat'
  }
</script>

<style scoped>

</style>