import {
  CHANGE_PROFILE,
  OPEN_MORE_MENU,
  OPEN_LOGOUT,
} from './constants'
import { RESET_STATE } from '@/store/constants'
import resetState from '@/store/helpers/resetState'

export const initialState = {
  moreMenu: false,
  logout: false,
  layout: false,
  switchProfile: false,
};

const mutations = {
  [CHANGE_PROFILE](state,value) {
    state.switchProfile = value;
    state.layout = value;
  },
  [OPEN_MORE_MENU](state, value) {
    state.moreMenu = value;
    state.layout = value;
  },
  [OPEN_LOGOUT](state, value) {
    state.logout = value;
    state.layout = value;
  },
  [RESET_STATE](state) {
    resetState(state, initialState);
  }
};

const actions = {
  resetState({ commit }) {
    commit(RESET_STATE)
  }
};

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions
}