<template>
	<div class="dashboard-page row">
		<div class="col-12">
			<BoardStat />
		</div>
<!--		<div class="col-12 col-lg-6">-->
<!--			<BoardContentPlan />-->
<!--		</div>-->
<!--		<div class="col-12 col-lg-6">-->
<!--			<BoardContentStats />-->
<!--		</div>-->
		<div class="col-12 col-lg-6">
			<BoardTotalSales :stats="stats.find((el) => el.active === true)" @input="setOption" />
		</div>
		<div class="col-12 col-lg-6">
			<BoardSalesStatistics :stats="stats" />
		</div>
<!--		<div class="col-12 col-lg-7">-->
<!--			<BoardLatestOrders />-->
<!--		</div>-->
		<div class="col-12 col-lg-6">
			<BoardFansCategories />
		</div>
	</div>
</template>

<script>
  import {mapState} from 'vuex';
  import BoardStat from "@/components/BoardStat";
  // import BoardContentPlan from "@/components/BoardContentPlan";
  // import BoardContentStats from "@/components/BoardContentStats";
  // import BoardLatestOrders from "@/components/BoardLatestOrders";
  import BoardFansCategories from "@/components/BoardFansCategories";
  import BoardSalesStatistics from "@/components/BoardSalesStatistics";
  import BoardTotalSales from "@/components/BoardTotalSales";
  import apiStats from '@/api/stats'

  export default {
    name: "DashboardPage",


    components: {
      BoardTotalSales,
      BoardSalesStatistics,
      BoardFansCategories,
      // BoardLatestOrders,
      // BoardContentStats,
      BoardStat,
      // BoardContentPlan
    },


    data() {
      return {
        stats: [],
        currentData: '',
        selectedMonth: '',
      }
    },


    computed: {
      ...mapState ({
        subProfileId: state => state.user.subProfileId,
      })
    },


    created() {
      const now = new Date();
      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(now);
      const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(now);

      this.currentData = `${year}-${month}-01`;

      this.getStatisticsSales(this.currentData);
    },


    watch: {
      subProfileId() {
        this.getStatisticsSales(this.selectedMonth);
      },
    },


    methods: {
      async getStatisticsSales(date) {
        try {
          // const now = new Date();
          // const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(now);
          this.stats = await apiStats.getStatisticsSales({date: date})
        } catch (error) {
          console.log(error)
        }
      },
      setOption(value) {
        this.selectedMonth = value;
        this.getStatisticsSales(this.selectedMonth)
      },
    }
  }
</script>

<style scoped lang="less">
	@import "@/assets/style/variables.less";
</style>