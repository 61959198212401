<template>
	<div class="right-sidebar">
<!--		<div class="right-sidebar__item">-->
<!--			<BoardOpenRequests />-->
<!--		</div>-->
<!--		<div class="right-sidebar__item">-->
<!--			<BoardWeeklyMessage />-->
<!--		</div>-->
		<div class="right-sidebar__item">
			<BoardMyTeam />
		</div>
		<div class="right-sidebar__item">
			<BoardMonthlyActivity />
		</div>
	</div>
</template>

<script>
  // import BoardOpenRequests from "@/components/BoardOpenRequests.vue";
  // import BoardWeeklyMessage from "@/components/BoardWeeklyMessage";
  import BoardMyTeam from "@/components/BoardMyTeam";
  import BoardMonthlyActivity from "@/components/BoardMonthlyActivity";

  export default {
    name: "RightSidebar",


    components: {
      BoardMonthlyActivity,
      BoardMyTeam,
      // BoardWeeklyMessage,
      // BoardOpenRequests,
    }
  }
</script>

<style scoped lang="less">
	.right-sidebar {

		&__item {

			&:not(:last-child) {
				margin-bottom: 32px;
			}
		}
	}
</style>