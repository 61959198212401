<template>
	<path d="M17.7077 7.625C15.7827 4.6 12.966 2.85834 9.99935 2.85834C8.51602 2.85834 7.07435 3.29167 5.75768 4.1C4.44102 4.91667 3.25768 6.10834 2.29102 7.625C1.45768 8.93334 1.45768 11.0583 2.29102 12.3667C4.21602 15.4 7.03268 17.1333 9.99935 17.1333C11.4827 17.1333 12.9243 16.7 14.241 15.8917C15.5577 15.075 16.741 13.8833 17.7077 12.3667C18.541 11.0667 18.541 8.93334 17.7077 7.625ZM9.99935 13.3667C8.13268 13.3667 6.63268 11.8583 6.63268 10C6.63268 8.14167 8.13268 6.63334 9.99935 6.63334C11.866 6.63334 13.366 8.14167 13.366 10C13.366 11.8583 11.866 13.3667 9.99935 13.3667Z"/>
	<path d="M10 7.61667C8.69167 7.61667 7.625 8.68334 7.625 10C7.625 11.3083 8.69167 12.375 10 12.375C11.3083 12.375 12.3833 11.3083 12.3833 10C12.3833 8.69167 11.3083 7.61667 10 7.61667Z"/>
</template>

<script>
  export default {
    name: 'IconVisitor'
  }
</script>

<style scoped>

</style>