<template>
	<div class="custom-select-frame">
		<div class="custom-select-border">
			<div
					class="custom-select"
					:tabindex="tabindex"
					@blur="open = false"
			>
				<div
						class="custom-select__selected"
						:class="{ open: open }"
						@click="open = !open"
				>
					<img class="custom-select__icon" src="@/assets/icon/calendar-outline-black.svg" alt="Calendar">
					<span>{{ selected.name }}</span>
				</div>
				<div
						class="custom-select__dropdown"
						:class="{ selectHide: !open }"
				>
					<div
							class="custom-select__item"
							:class="(selected === option ? 'is-active' : '')"
							v-for="option of options"
							:key="option.value"
							@click="[selected = option, open = false, $emit('input', option.value)]">
						<span>{{ option.name }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
    name: 'BaseSelect',


    props: {
      options: {
        type: Array,
        required: true,
      },
      default: {
        type: [String, Number],
        required: false,
        default: null,
      },
      tabindex: {
        type: Number,
        required: false,
        default: 0,
      },
    },


    data() {
      return {
        selected: this.default
          ? this.options.find((el) => el.value === this.default)
          : this.options.length > 0
            ? this.options[0]
            : null,
        open: false,
      };
    },


    mounted() {
      this.$emit('input', this.selected.value)
    },
  }
</script>

<style scoped lang="less">
	.custom-select-frame {
		width: 100%;
		height: 48px;
		position: relative;
	}

	.custom-select-border {
		background: linear-gradient(90deg, #ffe9b0 0%, #f2b5de 33%, #daf1ff 66%, #97b2ff 100%);
		padding: 1px;
		border-radius: 16px;
		overflow: hidden;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 2;
	}

	.custom-select {
		position: relative;
		text-align: left;
		outline: none;
		width: 100%;
		background-color: #fff;
		border-radius: 16px;

		&__selected {
			height: 46px;
			line-height: 46px;
			padding: 0 16px 0 40px;
			cursor: pointer;
			user-select: none;
			position: relative;

			&.open {

			}

			&:after {
				position: absolute;
				content: "";
				top: 22px;
				right: 1em;
				width: 0;
				height: 0;
				border: 5px solid transparent;
				border-color: #0B0A0A transparent transparent transparent;
			}
		}

		&__icon {
			display: block;
			width: 20px;
			position: absolute;
			left: 16px;
			top: 50%;
			transform: translateY(-50%);
		}

		&__dropdown {
			border-radius: 0 0 16px 16px;
			overflow: hidden;
			position: relative;
			padding-top: 1px;

			&:before {
				content: '';
				height: 1px;
				width: 100%;
				position: absolute;
				left: 0;
				top: 0;
				background: linear-gradient(90deg, #ffe9b0 0%, #f2b5de 33%, #daf1ff 66%, #97b2ff 100%);
			}

			&.selectHide {
				pointer-events: none;
				opacity: 0;
				height: 0;
			}
		}

		&__item {
			padding: 10px 16px;
			cursor: pointer;
			user-select: none;
			background-color: #fff;
			color: #8b8c91;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background: linear-gradient(90deg, #ffe9b0 0%, #f2b5de 33%, #daf1ff 66%, #97b2ff 100%);
				opacity: 0;
				transition: opacity .3s ease;
			}

			&:hover:before {
				opacity: .2;
			}

			&.is-active {
				color: #0B0A0A;
				font-weight: 500;
			}

			& span {
				position: relative;
			}
		}
	}
</style>