<template>
	<div class="notification-page">
		<BaseBack />
		<ul class="notification-list" v-if="items.length > 0">
			<li
					class="notification-bordered-list__item"
					v-for="item in items"
					:key="item.short_link"
			>
				<RouterLink :to="{ name: 'Detailed', params: {type: item.type_message, id: item.short_link} }" @click="getBellNotifications">
					<BaseNotification
							:data="item"
					/>
				</RouterLink>
			</li>
		</ul>
		<div class="notification-dropdown__empty" v-else>Empty list</div>
	</div>
</template>

<script>
  import { mapState } from 'vuex'
  import BaseBack from '@/components/BaseBack'
  import BaseNotification from "@/components/BaseNotification/BaseNotification";

  export default {
    name: 'NotificationsPage',


    components: {
      BaseBack,
      BaseNotification,
    },


    computed: {
      ...mapState({
        items: state => state.notifications.bellItems,
        countNew: state => state.notifications.countNew,
      })
    },


    created() {
      this.getBellNotifications();
    },


    methods: {
      async getBellNotifications() {
        this.$store.dispatch('notifications/getBellNotifications')
      }
    }
  }
</script>

<style scoped lang="less">
	.notification-page {

		& .notification {
			padding: 24px 0;
		}
	}
</style>