<template>
	<div class="bg-skeleton"></div>
</template>

<script>
  export default {
    name: 'BaseBgSkeleton'
  }
</script>

<style scoped lang="less">
	.bg-skeleton {
		width: 100%;
		height: 100%;
		/*background-color: #e6e8ea;*/

		background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
		border-radius: 5px;
		background-size: 200% 100%;
		animation: 1s shine linear infinite;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: #fff;
			opacity: 0;
			animation: pulse 1s linear infinite;
		}
	}

	@keyframes shine {
		0% { background-position-x: 0; }
		100% { background-position-x: -200%; }
	}

	@keyframes pulse {
		0% { opacity: .5; }
		50% { opacity: 0; }
		100% { opacity: .5; }
	}
</style>