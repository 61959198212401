<template>
	<div class="left-sidebar">

		<div class="left-sidebar__body">
			<div class="left-sidebar__logo">
				<router-link :to="'/'"><img src="@/assets/img/sparks-logo.png" alt="sparks-logo"></router-link>
			</div>
			<div class="left-sidebar__user-inf">
				<div class="user-inf">
					<div class="user-inf__avatar">
						<BaseBgSkeleton v-if="!userLoaded"/>
						<img v-else :src="userInfo.full_image_url" alt="Marry Johnson">
					</div>
					<div class="user-inf__name">{{ profileName }}</div>
					<div class="user-inf__mail">{{ userInfo.username }}</div>
					<div v-if="userLoaded && userInfo.sub_profiles.length > 0" class="user-inf__switch">
						<BaseButton small @click="toggleModalSwitchProfile">Switch profile</BaseButton>
					</div>
				</div>
			</div>
			<div class="left-sidebar__nav">
				<SidebarNav />
			</div>
		</div>

		<div class="left-sidebar__log-out">
			<div class="logout" @click="logout">
				<div class="logout__icon">
					<img src="@/assets/icon/logout.svg" alt="logout">
				</div>
				<div class="logout__caption">Log Out</div>
			</div>
		</div>


	</div>
</template>

<script>
	import { mapState } from 'vuex';
    import {CHANGE_PROFILE} from "@/store/modules/mainView/constants";
    import SidebarNav from "./components/SidebarNav";
    import BaseBgSkeleton from "@/components/BaseBgSkeleton";
    import BaseButton from "@/components/BaseButton";

  export default {
    name: 'LeftSidebar',


    components: {
      BaseButton,
      BaseBgSkeleton,
      SidebarNav
    },


    data() {
      return {
        profileName: '',
      }
    },


    computed: {
      ...mapState ({
        userInfo: state => state.user.info,
        subProfileId: state => state.user.subProfileId,
        userLoading: state => state.user.userLoading,
        userLoaded: state => state.user.userLoaded,
      }),
    },


    created() {
      this.getProfileName()
    },


    watch: {
      userLoaded(value) {
        if (value) {
          this.getProfileName()
        }
      },
      subProfileId() {
        this.getProfileName()
      },
    },


    methods: {
      logout() {
        this.$store.dispatch('resetState')
      },
      toggleModalSwitchProfile() {
        if (this.$store.state.mainView.switchProfile) {
          this.$store.commit(`mainView/${CHANGE_PROFILE}`, false)
        } else {
          this.$store.commit(`mainView/${CHANGE_PROFILE}`, true)
        }
      },
      getProfileName() {
        if (this.userLoaded) {
          if (localStorage.subProfileId) {
            const subProfiles = this.userInfo.sub_profiles;
            const selectedProfile = subProfiles.find((el) => el.id === Number(this.subProfileId));
            this.profileName = selectedProfile.nickname
          } else {
            this.profileName = this.userInfo.nickname
          }
        }
      },
    }
  }
</script>

<style scoped lang="less">
	@import "@/assets/style/variables.less";

	.left-sidebar {
		background-color: #F7F9FB;
		border-radius: 0 24px 24px 0;
		height: 100%;
		padding: 50px 0 50px 0;
		display: flex;
		flex-direction: column;

		&__body {
			flex: 1 1 auto;
		}

		&__logo {
			margin-bottom: 60px;

			& img {
				display: block;
				width: 100%;
				max-width: 172px;
				margin: 0 auto;
			}
		}

		&__user-inf {
			margin-bottom: 90px;
		}

		&__nav {

		}

		&__log-out {
			flex: 0 1 auto;
			display: flex;
			justify-content: center;
		}
	}

	.user-inf {
		padding: 0 36px;

		&__avatar {
			overflow: hidden;
			border-radius: 100%;
			width: 80px;
			height: 80px;
			margin: 0 auto 16px;
			border: 2px solid #C5C9CF;

			& img {
				object-fit: cover;
				display: block;
				width: 100%;
				height: 100%;
			}
		}

		&__name {
			font-family: 'Clash Display', sans-serif;
			font-weight: 700;
			font-size: 20px;
			text-align: center;
			margin-bottom: 4px;
		}

		&__mail {
			font-weight: 500;
			font-size: 14px;
			text-align: center;
		}

		&__switch {
			margin-top: 16px;
		}
	}

	.logout {
		display: flex;
		align-items: center;
		padding: 8px;
		cursor: pointer;

		&__icon {

		}

		&__caption {
			font-weight: 500;
			font-size: 18px;
			color: #4D4E53;
			padding-left: 20px;
		}
	}
</style>