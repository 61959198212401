<template>
	<template v-if="userLoaded">

		<div v-if="team.length > 0" class="my-team">

			<div class="my-team__title h6">My Team</div>

			<transition name="fade">

				<BaseCircleLoader v-if="!userLoaded"/>

				<div v-else class="my-team__body">
					<div class="team-list">
						<div class="team-list__item" v-for="member in team" :key="member.id">
							<div class="team-member">
								<div class="team-member__img">
									<img v-if="member.full_image_url" :src="member.full_image_url" :alt="member.full_name">
									<img v-else src="@/assets/img/no-photo.jpg" alt="no-photo">
								</div>
								<div class="team-member__text">
									<div class="team-member__name">{{ member.full_name }}</div>
									<div class="team-member__position">{{ member.role }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</transition>

		</div>

	</template>
</template>

<script>
  import {mapState} from 'vuex'
  import BaseCircleLoader from '@/components/BaseCircleLoader'

  export default {
    name: 'BoardMyTeam',


    components: {
      BaseCircleLoader
    },


    computed: {
      ...mapState({
        team: state => state.user.info.employee,
        userLoading: state => state.user.userLoading,
        userLoaded: state => state.user.userLoaded,
      }),
    }
  }
</script>

<style scoped lang="less">
	.my-team {

		&__title {
			margin-bottom: 16px;
		}

		&__body {

		}
	}

	.team-list {

		&__item {

			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
	}

	.team-member {
		display: flex;

		&__img {
			overflow: hidden;
			border-radius: 100%;
			width: 40px;
			height: 40px;
			position: relative;

			& img {
				position: absolute;
				left: 0;
				top: 0;
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		&__text {
			padding-left: 12px;
		}

		&__name {
			font-size: 14px;
			font-weight: 700;
			margin-bottom: 4px;
		}

		&__position {
			font-size: 12px;
			color: #4D4E53;
		}
	}
</style>