<template>
	<div class="profiles-list">
		<div class="profiles-list__item">
			<div :class="activeUserId === false ? 'is-active' : ''" class="profile-item" @click="deleteSubProfileId">
				<div class="profile-item__name">{{ userInfo.nickname }}</div>
				<div v-if="activeUserId === false" class="profile-item__check">✓</div>
			</div>
		</div>
		<template v-for="item in userInfo.sub_profiles" :key="item.id">
			<div class="profiles-list__item">
				<div :class="item.id == activeUserId ? 'is-active' : ''" class="profile-item" @click="setSubProfileId(item.id)">
					<div class="profile-item__name">{{item.nickname}}</div>
					<div v-if="item.id == activeUserId" class="profile-item__check">✓</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
  import {mapState} from 'vuex';
  import {CHANGE_PROFILE} from "@/store/modules/mainView/constants";
  import {SET_SUB_PROFILE_ID_FROM_STORAGE} from "@/store/modules/user/constants";

  export default {
    name: 'ProfilesList',


    components: {

    },


    computed: {
      ...mapState ({
        userInfo: state => state.user.info,
      }),
      activeUserId() {
        if (localStorage.subProfileId) {
          return localStorage.subProfileId
        } else {
          return false
        }
      }
    },


    methods: {
      setSubProfileIdFromStorage() {
        const id = localStorage.getItem('subProfileId');
        this.$store.commit(`user/${SET_SUB_PROFILE_ID_FROM_STORAGE}`, id)
      },
      setSubProfileId(id) {
        localStorage.setItem('subProfileId', id);
        this.$store.commit(`mainView/${CHANGE_PROFILE}`, false);
        this.setSubProfileIdFromStorage()
      },
      deleteSubProfileId() {
        localStorage.setItem('subProfileId', '');
        this.$store.commit(`mainView/${CHANGE_PROFILE}`, false);
        this.setSubProfileIdFromStorage()
      },
    }
  }
</script>

<style scoped lang="less">
	.profiles-list {

		&__item {
			margin-bottom: 16px;
		}
	}

	.profile-item {
		padding: 8px 16px;
		background-color: #FCFCFD;
		border-radius: 24px;
		display: flex;
		justify-content: space-between;
		cursor: pointer;
		transition: background-color .3s ease;

		&:hover {
			background-color: #f2f2f3;
		}

		&.is-active {
			pointer-events: none;
		}

		&__name {
			font-size: 16px;
		}

		&__check {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 20px;
			height: 20px;
			border-radius: 100%;
			font-weight: 700;
			background-color: rgba(185, 221, 121, 0.4);
			color: #93BC48;
			font-size: 12px;
		}
	}
</style>