<template>
	<div class="more-menu" :class="moreMenu ? 'is-active' : ''">
		<div class="more-menu__head">
			<div class="more-menu-profile">
				<div class="more-menu-profile__avatar">
					<div class="more-menu-profile__avatar-img">
						<img :src="userInfo.full_image_url" alt="Marry Johnson">
					</div>
				</div>
				<div class="more-menu-profile__text">
					<div class="more-menu-profile__name">{{ profileName }}</div>
					<div class="more-menu-profile__mail">{{ userInfo.username }}</div>
				</div>
			</div>
			<div v-if="userLoaded && userInfo.sub_profiles.length > 0" class="more-menu__switch">
				<BaseButton small @click="toggleModalSwitchProfile">Switch profile</BaseButton>
			</div>
		</div>
		<div class="more-menu__body">
			<div class="more-menu-list">
<!--				<div class="more-menu-list__item">-->
<!--					<RouterLink :to="'/my-team'" @click="closeMoreMenu">-->
<!--						<div class="more-menu-item">-->
<!--							<div class="more-menu-item__icon">-->
<!--								<img src="@/assets/icon/people-outline-black.svg" alt="My Team">-->
<!--							</div>-->
<!--							<div class="more-menu-item__caption">My Team</div>-->
<!--							<div class="more-menu-item__arrow">-->
<!--								<img src="@/assets/icon/arrow-down.svg" alt="arrow">-->
<!--							</div>-->
<!--						</div>-->
<!--					</RouterLink>-->
<!--				</div>-->
<!--				<div class="more-menu-list__item">-->
<!--					<RouterLink :to="'/monthly-activity'" @click="closeMoreMenu">-->
<!--						<div class="more-menu-item">-->
<!--							<div class="more-menu-item__icon">-->
<!--								<img src="@/assets/icon/calendar-outline-black.svg" alt="Monthly activity">-->
<!--							</div>-->
<!--							<div class="more-menu-item__caption">Monthly activity</div>-->
<!--							<div class="more-menu-item__arrow">-->
<!--								<img src="@/assets/icon/arrow-down.svg" alt="arrow">-->
<!--							</div>-->
<!--						</div>-->
<!--					</RouterLink>-->
<!--				</div>-->
				<div class="more-menu-list__item">
					<RouterLink :to="'/'" @click="closeMoreMenu">
						<div class="more-menu-item">
							<div class="more-menu-item__icon">
								<img src="@/assets/icon/dashboard-outline-black.svg" alt="Dashboard">
							</div>
							<div class="more-menu-item__caption">Dashboard</div>
							<div class="more-menu-item__arrow">
								<img src="@/assets/icon/arrow-down.svg" alt="arrow">
							</div>
						</div>
					</RouterLink>
				</div>
<!--				<div class="more-menu-list__item">-->
<!--					<RouterLink :to="'/inbox'" @click="closeMoreMenu">-->
<!--						<div class="more-menu-item">-->
<!--							<div class="more-menu-item__icon">-->
<!--								<img src="@/assets/icon/bell-outline-black.svg" alt="Notification">-->
<!--							</div>-->
<!--							<div class="more-menu-item__caption">Notification</div>-->
<!--							<div class="more-menu-item__arrow">-->
<!--								<img src="@/assets/icon/arrow-down.svg" alt="arrow">-->
<!--							</div>-->
<!--						</div>-->
<!--					</RouterLink>-->
<!--				</div>-->
				<div class="more-menu-list__item">
					<div class="more-menu-item" @click="openLogout">
						<div class="more-menu-item__icon">
							<img src="@/assets/icon/logout-black.svg" alt="Log Out">
						</div>
						<div class="more-menu-item__caption">Log Out</div>
						<div class="more-menu-item__arrow">
							<img src="@/assets/icon/arrow-down.svg" alt="arrow">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import { mapState } from 'vuex'
  import {OPEN_LOGOUT, OPEN_MORE_MENU} from "@/store/modules/mainView/constants";
  import {CHANGE_PROFILE} from "@/store/modules/mainView/constants";
  import BaseButton from "@/components/BaseButton";

  export default {
    name: 'MoreMenu',


    components: {
      BaseButton,
    },


    data() {
      return {
        profileName: '',
      }
    },


    computed: {
      ...mapState({
        subProfileId: state => state.user.subProfileId,
        userInfo: state => state.user.info,
        userLoading: state => state.user.userLoading,
        userLoaded: state => state.user.userLoaded,
        moreMenu: state => state.mainView.moreMenu,
      }),
    },


    created() {
      this.getProfileName()
    },


    watch: {
      userLoaded(value) {
        if (value) {
          this.getProfileName()
        }
      },
      subProfileId() {
        this.getProfileName()
      },
    },


    methods: {
      closeMoreMenu() {
        this.$store.commit(`mainView/${OPEN_MORE_MENU}`, false)
      },
      openLogout() {
        this.closeMoreMenu();
        this.$store.commit(`mainView/${OPEN_LOGOUT}`, true)
      },
      toggleModalSwitchProfile() {
        this.closeMoreMenu();
        if (this.$store.state.mainView.switchProfile) {
          this.$store.commit(`mainView/${CHANGE_PROFILE}`, false)
        } else {
          this.$store.commit(`mainView/${CHANGE_PROFILE}`, true)
        }
      },
      getProfileName() {
        if (this.userLoaded) {
          if (localStorage.subProfileId) {
            const subProfiles = this.userInfo.sub_profiles;
            const selectedProfile = subProfiles.find((el) => el.id === Number(this.subProfileId));
            this.profileName = selectedProfile.nickname
          } else {
            this.profileName = this.userInfo.nickname
          }
        }
      },
    },
  }
</script>

<style scoped lang="less">
	@import "@/assets/style/variables.less";

	.more-menu {
		position: fixed;
		bottom: 0;
		width: 100%;
		border-radius: 24px 24px 0 0;
		overflow: hidden;
		transform: translateY(100%);
		transition: transform .2s ease;
		pointer-events: none;
		z-index: 3;

		&.is-active {
			transform: translateY(0);
			pointer-events: auto;
		}

		&__head {
			background-color: #F7F9FB;
			padding: 32px 24px;
		}

		&__switch {
			margin-top: 16px;
		}

		&__body {
			background-color: #fff;
			padding-bottom: 100px;
		}
	}

	.more-menu-profile {
		display: flex;
		gap: 16px;

		&__avatar {
			padding: 2px;
			background-image: linear-gradient(to bottom, #D8E0E8, #F5EFFB);
			width: 56px;
			height: 56px;
			border-radius: 100%;
		}

		&__avatar-img {
			border-radius: 100%;
			width: 100%;
			height: 100%;
			overflow: hidden;
			position: relative;

			& img {
				display: block;
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		&__text {

		}

		&__name {
			font-family: 'Clash Display';
			font-weight: 700;
			font-size: 20px;
			margin-bottom: 4px;
		}

		&__mail {
			font-weight: 500;
			font-size: 14px;
		}
	}

	.more-menu-list {

		&__item {

		}
	}

	.more-menu-item {
		padding: 16px 24px;
		display: flex;
		align-items: center;

		&__icon {

			& img {
				width: 24px;
			}
		}

		&__caption {
			font-weight: 700;
			font-size: 14px;
			flex: 1 1 auto;
			padding: 0 12px;
		}

		&__arrow {
			border: 1px solid #EDEEF1;
			width: 44px;
			height: 44px;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			& img {
				width: 12px;
				transform: rotate(-90deg);
			}
		}
	}
</style>