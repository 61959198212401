import axios from "@/services/axios-instance";

export default {
  async getBellNotifications() {
    const response = await axios.get('/notifications/');
    return response.data
  },

  async getNotificationsByType(params) {
    const response = await axios.get('/notifications/all-details/', {params});
    return response.data
  },

  async getNotificationPageData(short_link) {
    const response = await axios.get(`/notifications/${short_link}/`);
    return response.data
  },

  async getOpenRequests() {
    const response = await axios.get('/notifications/requests/');
    return response.data
  },

  async getWeeklyMessageInfo() {
    const response = await axios.get('/notifications/weekly/');
    return response.data
  },

  async getContentPlan() {
    const response = await axios.get('/notifications/content-plan/');
    return response.data
  },
}