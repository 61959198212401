<template>
	<path d="M8.54102 8.35833C8.54102 8.80833 8.66602 8.875 8.94935 8.975L9.37435 9.12499V7.70833H9.12435C8.80768 7.70833 8.54102 7.99999 8.54102 8.35833Z" />
	<path d="M10.625 12.2917H10.875C11.2 12.2917 11.4583 12 11.4583 11.6417C11.4583 11.1917 11.3333 11.125 11.05 11.025L10.625 10.875V12.2917Z" />
	<path d="M16.316 4.56667L14.6077 6.27501C14.4827 6.40001 14.3243 6.45834 14.166 6.45834C14.0077 6.45834 13.8493 6.40001 13.7243 6.27501C13.4827 6.03334 13.4827 5.63334 13.7243 5.39167L15.4327 3.68334C13.966 2.43334 12.0743 1.66667 9.99935 1.66667C5.39935 1.66667 1.66602 5.40001 1.66602 10C1.66602 14.6 5.39935 18.3333 9.99935 18.3333C14.5993 18.3333 18.3327 14.6 18.3327 10C18.3327 7.925 17.566 6.03334 16.316 4.56667ZM11.4577 9.85C11.991 10.0417 12.7077 10.425 12.7077 11.65C12.7077 12.6917 11.8827 13.55 10.8743 13.55H10.6243V13.7583C10.6243 14.1 10.341 14.3833 9.99935 14.3833C9.65768 14.3833 9.37435 14.1 9.37435 13.7583V13.55H9.30768C8.19935 13.55 7.29102 12.6167 7.29102 11.4667C7.29102 11.1167 7.57435 10.8333 7.91602 10.8333C8.25768 10.8333 8.54102 11.1167 8.54102 11.4583C8.54102 11.9167 8.88268 12.2917 9.30768 12.2917H9.37435V10.4417L8.54102 10.15C8.00768 9.95834 7.29102 9.57501 7.29102 8.35001C7.29102 7.30834 8.11602 6.45 9.12435 6.45H9.37435V6.25C9.37435 5.90834 9.65768 5.62501 9.99935 5.62501C10.341 5.62501 10.6243 5.90834 10.6243 6.25V6.45834H10.691C11.7993 6.45834 12.7077 7.39167 12.7077 8.54167C12.7077 8.88334 12.4243 9.16667 12.0827 9.16667C11.741 9.16667 11.4577 8.88334 11.4577 8.54167C11.4577 8.08334 11.116 7.70834 10.691 7.70834H10.6243V9.55834L11.4577 9.85Z" />
	<path d="M18.9083 1.42501C18.8417 1.27501 18.725 1.15001 18.5667 1.08334C18.4917 1.05834 18.4167 1.04167 18.3333 1.04167H15C14.6583 1.04167 14.375 1.32501 14.375 1.66667C14.375 2.00834 14.6583 2.29167 15 2.29167H16.825L15.4333 3.68334C15.75 3.95834 16.0417 4.25001 16.3167 4.56667L17.7083 3.17501V5.00001C17.7083 5.34167 17.9917 5.62501 18.3333 5.62501C18.675 5.62501 18.9583 5.34167 18.9583 5.00001V1.66667C18.9583 1.58334 18.9417 1.50834 18.9083 1.42501Z" />
</template>

<script>
  export default {
    name: 'IconMoney'
  }
</script>

<style scoped>

</style>