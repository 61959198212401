import api from "@/api/notifications";
import {
  SET_NOTIFICATIONS,
  SET_LOADING,
  SET_LOADED,
  SET_BELL_NOTIFICATIONS,
  SET_BELL_LOADING,
  SET_BELL_LOADED,
  SET_COUNT_NEW,
} from "@/store/modules/notifications/constants";
import {RESET_STATE} from "@/store/constants";
import resetState from "@/store/helpers/resetState";

export const initialState = {
  items: [],
  itemsLoading: false,
  itemsLoaded: false,
  bellItems: [],
  bellItemsLoading: false,
  bellItemsLoaded: false,
  countNew: 0,
};

const mutations = {
  [SET_NOTIFICATIONS](state, data) {
    state.items = data
  },
  [SET_LOADING](state, value) {
    state.itemsLoading = value
  },
  [SET_LOADED](state, value) {
    state.itemsLoaded = value
  },
  [SET_BELL_NOTIFICATIONS](state, data) {
    state.bellItems = data
  },
  [SET_BELL_LOADING](state, value) {
    state.bellItemsLoading = value
  },
  [SET_BELL_LOADED](state, value) {
    state.bellItemsLoaded = value
  },
  [SET_COUNT_NEW](state, value) {
    state.countNew = value
  },
  [RESET_STATE](state) {
    resetState(state, initialState)
  }
};

//временная функция для выбора уведомлений типа реквест и отсутствующей датой старта (существует с 09.05.2023)
function isValid (el) {
  return !(el.request_start_date === null && el.type_message === 'request')
}

const actions = {
  async getNotificationsByType({commit}, params){
    commit(SET_LOADING, true);
    try {
      const response = await api.getNotificationsByType(params);
      const data = response.results.filter(isValid);
      commit(SET_NOTIFICATIONS, data);
      commit(SET_LOADED, true)
    } catch (err) {
      console.log('get notifications error: ', err)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async getBellNotifications({commit, state}) {
    commit(SET_BELL_LOADING, true);
    try {
      const response = await api.getBellNotifications();
      const data = response.filter(isValid);
      commit(SET_BELL_NOTIFICATIONS, data);
      commit(SET_BELL_LOADED, true);
      let counter = 0;
      data.forEach((item) => {
        if (item.is_read === false) {
          counter++
        }
      });
      if (state.countNew !== counter) {
        commit(SET_COUNT_NEW, counter)
      }
    } catch (err) {
      console.log('get bell notifications error: ', err)
    } finally {
      commit(SET_BELL_LOADING, false)
    }
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  }
};

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
}