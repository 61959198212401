<template>
	<div class="welcome">
		<div class="welcome__inner">
			<div class="welcome__text-wrap">
				<div class="welcome__text">
					<div class="welcome__title">New Digital<br> soulution for<br> remote work</div>
					<div class="welcome__subtitle">All information in one place. We make your life more comfortable.</div>
				</div>
			</div>
			<div class="welcome__logo">
				<img src="@/assets/img/sparks-logo-1.svg" alt="sparks-logo">
			</div>
		</div>
	</div>
</template>

<script>
  export default {
    name: 'TheWelcome'
  }
</script>

<style scoped lang="less">
	@import "@/assets/style/variables.less";

	.welcome {
		height: 100%;
		background-image: linear-gradient(167.5deg, #FFFFFF 11.44%, #DFE6FA 38.03%, #C8E0EE 79.4%, #FDD7F0 106%);
		position: relative;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: url("@/assets/img/noise.png");
			opacity: .05;
		}

		&__inner {
			height: 100%;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			max-width: 820px;
			margin-left: auto;
			position: relative;
		}

		&__text-wrap {
			flex: 1 1 auto;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&__text {

		}

		&__title {
			font-size: 64px;
			font-family: 'Clash Display', sans-serif;
			font-weight: 700;
			margin-bottom: 20px;

			&:before {
				content: '';
				display: inline-block;
				border: 23px solid transparent;
				border-left: 28px solid @color-black;
				border-right: 0;
				margin-right: 28px;
			}
		}

		&__subtitle {
			font-size: 18px;
			line-height: 24px;
			font-weight: 500;
			max-width: 400px;
		}

		&__logo {
			flex: 0 1 auto;
			padding: 0 50px 100px;

			& img {
				display: block;
				width: 100%;
			}
		}
	}
</style>