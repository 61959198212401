import { createRouter, createWebHistory } from "vue-router";

import AuthPage from '@/components/AuthScreen/AuthScreen.vue'
import AppMain from '@/components/AppMain.vue'
import Dashboard from '@/pages/DashboardPage.vue'
// import Inbox from '@/pages/InboxPage.vue'
// import DetailedNotificationPage from '@/pages/DetailedNotificationPage'
import MyTeamPage from "@/pages/MyTeamPage";
import MonthlyActivityPage from "@/pages/MonthlyActivityPage";
import NotFoundPage from "@/pages/NotFoundPage";
import NotificationsPage from "@/pages/NotificationsPage";

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: AuthPage,
  },
  {
    path: '/',
    name: 'App',
    component: AppMain,
    meta: {
      requiresAuth: true,
    },
    redirect: { name: 'Dashboard' },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { title: 'Dashboard' }
      },
      {
        path: 'inbox',
        redirect: '/inbox/notification',
      },
      // {
      //   path: 'inbox/:type',
      //   name: 'Inbox',
      //   component: Inbox,
      //   props: true,
      //   meta: { title: 'Inbox' },
      // },
      // {
      //   path: 'inbox/:type/:id',
      //   name: 'Detailed',
      //   component: DetailedNotificationPage,
      //   props: true,
      //   meta: { title: 'Inbox' }
      // },
      {
        path: 'my-team',
        name: 'My team',
        component: MyTeamPage,
        meta: { title: 'My team' }
      },
      {
        path: 'monthly-activity',
        name: 'Monthly activity',
        component: MonthlyActivityPage,
        meta: { title: 'Monthly activity' }
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: NotificationsPage,
        meta: { title: 'Notifications' }
      },
    ],
  },
  {
    path: '/api/*',
    redirect: { name: 'Not Found' }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: NotFoundPage,
  },
];

const router = new createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const authStatus = JSON.parse(localStorage.getItem('authenticated'));
    console.log('authStatus: ', authStatus);
    if (!authStatus) {
      console.log('zaloginsya');
      router.push({ name: 'Auth' })
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router