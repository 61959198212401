<template>
	<div class="back" @click="goBack">
		<div class="back__icon">
			<img src="@/assets/icon/arrow-down.svg" alt="back">
		</div>
		<div class="back__caption">Back</div>
	</div>
</template>

<script>
  export default {
    name: 'BaseBack',


    methods: {
      goBack() {
        if (window.history.state.back !== null && window.history.length > 1) {
          this.$router.go(-1)
        } else {
          this.$router.push('/')
        }
      },
    }
  }
</script>

<style scoped lang="less">
	.back {
		display: flex;
		align-items: center;
		width: fit-content;
		padding-right: 16px;
		cursor: pointer;

		&__icon {
			border: 1px solid #EDEEF1;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;

			& img {
				transform: rotate(90deg);
			}
		}

		&__caption {
			font-weight: 700;
			font-size: 16px;
			margin-left: 12px;
		}
	}
</style>