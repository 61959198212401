<template>
	<ul class="sidebar-nav">
		<li class="sidebar-nav__item">
			<router-link :to="'/dashboard'">
				<div class="nav-link">
					<div class="nav-link__icon">
						<img
								v-if="$route.meta.title === 'Dashboard'"
								src="@/assets/icon/dashboard.svg"
								alt="dashboard"
						>
						<img
								v-else
								src="@/assets/icon/dashboard-fill-gray.svg"
								alt="dashboard"
						>
					</div>
					<div class="nav-link__caption">Dashboard</div>
				</div>
			</router-link>
		</li>
		<li class="sidebar-nav__item">
<!--			<RouterLink :to="'/inbox'" :class="$route.meta.title === 'Inbox' ? 'router-link-active' : ''">-->
<!--				<div class="nav-link">-->
<!--					<div class="nav-link__icon">-->
<!--						<img-->
<!--								v-if="$route.meta.title === 'Inbox'"-->
<!--								src="@/assets/icon/inbox.svg"-->
<!--								alt="inbox"-->
<!--						>-->
<!--						<img-->
<!--								v-else-->
<!--								src="@/assets/icon/inbox-black.svg"-->
<!--								alt="inbox"-->
<!--						>-->
<!--					</div>-->
<!--					<div class="nav-link__caption">Inbox</div>-->
<!--				</div>-->
<!--			</RouterLink>-->
		</li>
	</ul>
</template>

<script>
  export default {
    name: 'SidebarNav'
  }
</script>

<style scoped lang="less">
	@import "@/assets/style/variables.less";

	.sidebar-nav {

		&__item {

			&:not(:last-child) {
				margin-bottom: 32px;
			}
		}
	}

	.nav-link {
		position: relative;
		padding-left: 36px;
		display: flex;
		align-items: center;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 4px;
			background-color: rgba(11, 10, 10, 0);
			transition: background-color .2s ease;

			.router-link-active & {
				background-color: @color-text;
			}
		}

		&__icon {
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			background-color: rgba(11, 10, 10, 0);
			transition: background-color .2s ease;

			.router-link-active & {
				background-color: @color-text;
			}

			& img {
				display: block;
				width: 24px;
			}
		}

		&__caption {
			color: #4D4E53;
			font-size: 18px;
			font-weight: 500;
			padding-left: 12px;

			.router-link-active & {
				color: @color-text;
			}
		}
	}
</style>