<template>
	<div class="monthly-activity">

		<div class="monthly-activity__title h6">Monthly activity</div>

		<transition name="fade">

			<BaseCircleLoader v-if="!loaded || loading"/>

			<div v-else class="monthly-activity__body">
				<div class="monthly-activity__item">
					<span>Private messages:</span><span>{{ monthlyActivity.sent_messages }}</span>
				</div>
				<div class="monthly-activity__item">
					<span>Mass messages:</span><span>{{ monthlyActivity.mailing }}</span>
				</div>
<!--				<div class="monthly-activity__item">-->
<!--					<span>Subscriptions:</span><span>{{ monthlyActivity.subscription }}</span>-->
<!--				</div>-->
				<div class="monthly-activity__item">
					<span>Purchases OF:</span><span>{{ monthlyActivity.purchases_of }}</span>
				</div>
			</div>

		</transition>

	</div>
</template>

<script>
  import {mapState} from 'vuex';
  import apiStats from '@/api/stats'
  import BaseCircleLoader from "@/components/BaseCircleLoader";

  export default {
    name: 'BoardMonthlyActivity',


    components: {
      BaseCircleLoader
    },


    data() {
      return {
        monthlyActivity: {},
        loading: false,
        loaded: false,
      }
    },


    computed: {
      ...mapState ({
        subProfileId: state => state.user.subProfileId,
      })
    },


    created() {
      this.getMonthlyActivity()
    },


    watch: {
      subProfileId() {
        this.getMonthlyActivity()
      },
    },


    methods: {
      async getMonthlyActivity() {
        try {
          this.loading = true;
          this.monthlyActivity = await apiStats.getMonthlyActivity()
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false;
          this.loaded = true
        }
      },
    }
  }
</script>

<style scoped lang="less">

	.monthly-activity {

		&__title {
			margin-bottom: 16px;
		}

		&__body {

		}

		&__item {
			display: flex;
			justify-content: flex-start;
			gap: 8px;

			&:not(:last-child) {
				margin-bottom: 16px;
			}

			& span:nth-child(1) {
				color: #4D4E53;
				font-size: 16px;
			}

			& span:nth-child(2) {
				font-family: 'Clash Display';
				font-weight: 700;
				font-size: 16px;
			}
		}
	}
</style>