<template>
	<RouterView></RouterView>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'App',


    components: {},


    computed: {
      ...mapState({
        authenticated: state => state.user.authenticated
      }),
    },


    created() {

    },


    methods: {}
  }
</script>

<style lang="less">
	@import "@/assets/style/variables.less";

	#app {
		min-height: 100vh;
		height: 100%;
		line-height: 1.2;
		color: @color-text;
		font-family: 'Jost', sans-serif;
	}
</style>
