<template>

	<transition name="fade">

		<BaseCircleLoader v-if="!userLoaded"/>

		<template v-else>

			<div class="team-list" v-if="team.length > 0">
				<div class="team-list__item" v-for="member in team" :key="member.id">
					<div class="team-member">
						<div class="team-member__img">
							<img :src="member.full_image_url" :alt="member.full_name">
						</div>
						<div class="team-member__text">
							<div class="team-member__name">{{ member.full_name }}</div>
							<div class="team-member__position">{{ member.role }}</div>
						</div>
					</div>
				</div>
			</div>

			<div v-else>Empty list</div>

		</template>

	</transition>

</template>

<script>
  import {mapState} from 'vuex'
  import BaseCircleLoader from '@/components/BaseCircleLoader'

  export default {
    name: 'MyTeamPage',


    components: {
      BaseCircleLoader
    },


    computed: {
      ...mapState({
        team: state => state.user.info.employee,
        userLoading: state => state.user.userLoading,
        userLoaded: state => state.user.userLoaded,
      }),
    }
  }
</script>

<style scoped lang="less">
	.team-list {

		&__item {

			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
	}

	.team-member {
		display: flex;

		&__img {
			overflow: hidden;
			border-radius: 100%;
			width: 40px;
			height: 40px;
			position: relative;

			& img {
				position: absolute;
				left: 0;
				top: 0;
				object-fit: contain;
				width: 100%;
				height: 100%;
			}
		}

		&__text {
			padding-left: 12px;
		}

		&__name {
			font-size: 14px;
			font-weight: 700;
			margin-bottom: 4px;
		}

		&__position {
			font-size: 12px;
			color: #4D4E53;
		}
	}
</style>