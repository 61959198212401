<template>
	<div
			class="notification"
			:class="[data.request_end_date ? 'notification--done' : 'notification--open', isOpen ? 'is-show' : '']"
	>
		<div class="notification__head">
			<div class="notification__status">
				<span v-if="data.request_end_date">✓</span>
				<span v-else>!</span>
			</div>
			<div class="notification__date">
				<div class="notification__start">{{ formatDate(data.request_start_date) }}</div>
				<div  class="notification__date-separator">-</div>
				<template v-if="data.request_end_date">
					<div class="notification__end">{{ formatDate(data.request_end_date) }}</div>
				</template>
				<div v-else class="notification__end">
					<div class="notification__label">waiting</div>
				</div>
			</div>
			<div class="notification__toggle">
				<span @click="toggle">
					<img src="@/assets/icon/arrow-down.svg" alt="toggle">
				</span>
			</div>
		</div>
		<div class="notification__body" ref="panel">
			<div v-if="data.preview" class="notification__text">{{ data.preview}} <RouterLink class="read-more-link" :to="{ name: 'Detailed', params: {type: 'request', id: data.short_link} }">&nbsp;&nbsp;Read more</RouterLink></div>
		</div>
	</div>
</template>

<script>
  import formatDate from '@/services/format-date'

  export default {
    name: 'StyleAccordion',


    props: {
      data: {
        type: Object,
        default: null,
      }
    },


    data() {
      return {
        isOpen: false,
      }
    },


    methods: {
      toggle() {
        this.isOpen = !this.isOpen;
        const panel = this.$refs.panel;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      },
      formatDate(value) {
        return formatDate(value)
      },
    }
  }
</script>

<style scoped lang="less">
	.notification {
		padding: 16px;
		background-color: #FCFCFD;
		border-radius: 14px;

		&__head {
			display: flex;
			align-items: center;
		}

		&__status {
			padding: 4px 0;
			margin-right: 14px;

			& span {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 20px;
				height: 20px;
				border-radius: 100%;
				font-weight: 700;

				.notification--open & {
					background-color: rgba(216, 80, 65, 0.4);
					color: #C25144;
					font-size: 14px;
				}

				.notification--done & {
					background-color: rgba(185, 221, 121, 0.4);
					color: #93BC48;
					font-size: 12px;
				}
			}
		}

		&__date {
			display: flex;
			align-items: center;
			flex: 1 1 auto;
			justify-content: flex-start;
		}

		&__start {
			font-size: 14px;
			font-weight: 500;
		}

		&__date-separator {
			padding: 0 4px;
		}

		&__end {
			font-size: 14px;
			font-weight: 500;
		}

		&__label {
			background-color: #FFF6E7;
			color: #C58F34;
			font-size: 12px;
			font-weight: 700;
			height: 28px;
			padding: 0 12px;
			display: flex;
			align-items: center;
			border-radius: 8px;
		}

		&__toggle {
			margin-left: 4px;

			span {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #F4F4F4;
				border-radius: 12px;
				width: 32px;
				height: 32px;
				cursor: pointer;

				& img {
					width: 12px;
					transform: rotate(0);
					transition: transform .2s ease;

					.notification.is-show & {
						transform: rotate(180deg);
					}
				}
			}
		}

		&__body {
			padding-top: 0;
			margin-top: 0;
			overflow: hidden;
			max-height: 0;
			transition: max-height .3s ease;
		}

		&__text {
			border-top: 1px solid #EDEEF1;
			padding-top: 8px;
			margin-top: 12px;
			line-height: 20px;
		}
	}

	.read-more-link {
		color: #6788E4;
		font-weight: 500;
		font-size: 12px;
		transition: color .2s ease;

		&:hover {
			color: #3251a7;
		}
	}
</style>