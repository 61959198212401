<template>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M1.66602 10C1.66602 5.39763 5.39698 1.66667 9.99935 1.66667C14.6017 1.66667 18.3327 5.39763 18.3327 10C18.3327 14.6024 14.6017 18.3333 9.99935 18.3333C5.39698 18.3333 1.66602 14.6024 1.66602 10ZM9.37435 4.16667C9.37435 3.82149 9.65417 3.54167 9.99935 3.54167C13.5662 3.54167 16.4577 6.43317 16.4577 10C16.4577 13.5668 13.5662 16.4583 9.99935 16.4583C6.43251 16.4583 3.54102 13.5668 3.54102 10C3.54102 9.65483 3.82084 9.37501 4.16602 9.37501C4.51119 9.37501 4.79102 9.65483 4.79102 10C4.79102 12.8765 7.12287 15.2083 9.99935 15.2083C12.8758 15.2083 15.2077 12.8765 15.2077 10C15.2077 7.12352 12.8758 4.79167 9.99935 4.79167C9.65417 4.79167 9.37435 4.51185 9.37435 4.16667ZM9.99935 6.04167C9.65417 6.04167 9.37435 6.32149 9.37435 6.66667C9.37435 7.01185 9.65417 7.29167 9.99935 7.29167C11.4951 7.29167 12.7077 8.50423 12.7077 10C12.7077 11.4958 11.4951 12.7083 9.99935 12.7083C9.65417 12.7083 9.37435 12.9882 9.37435 13.3333C9.37435 13.6785 9.65417 13.9583 9.99935 13.9583C12.1855 13.9583 13.9577 12.1861 13.9577 10C13.9577 7.81388 12.1855 6.04167 9.99935 6.04167Z"/>
</template>

<script>
  export default {
    name: 'IconRoundGraph'
  }
</script>

<style scoped>

</style>