<template>
	<div class="row">
		<div class="col col-6 col-lg-3">
			<div class="stat-block stat-block--revenue">
				<div class="stat-block__icon">
					<BaseIcon :size="20" :color="'#CDAB53'" :name="'money'"><IconMoney /></BaseIcon>
				</div>
				<div class="stat-block__value">${{ format(revenue) }}</div>
				<div class="stat-block__name">Sales</div>
			</div>
		</div>
		<div class="col col-6 col-lg-3">
			<div class="stat-block stat-block--plan">
				<div class="stat-block__icon">
					<BaseIcon :size="20" :color="'#6788E4'" :name="'stat'"><IconStat /></BaseIcon>
				</div>
				<div class="stat-block__value">${{ format(revenuePlan) }}</div>
				<div class="stat-block__name">Sales plan</div>
			</div>
		</div>
		<div class="col col-6 col-lg-3">
			<div class="stat-block stat-block--rate">
				<div class="stat-block__icon">
					<BaseIcon :size="20" :color="'#AC4389'" :name="'round graph'"><IconRoundGraph /></BaseIcon>
				</div>
				<div class="stat-block__value">${{ format(runRate) }}</div>
				<div class="stat-block__name">Expectation</div>
			</div>
		</div>
		<div class="col col-6 col-lg-3">
			<div class="stat-block stat-block--visitors">
				<div class="stat-block__icon">
					<BaseIcon :size="20" :color="'#5968B1'" :name="'visitor'"><IconVisitor /></BaseIcon>
				</div>
				<div class="stat-block__value">{{ format(visitors) }}</div>
				<div class="stat-block__name">Subscriptions</div>
			</div>
		</div>
	</div>
</template>

<script>
  import {mapState} from 'vuex';
  import apiStats from '@/api/stats'
  import format from '@/services/format-number'
  import BaseIcon from '@/components/BaseIcon'
  import IconMoney from '@/components/icons/IconMoney'
  import IconStat from '@/components/icons/IconStat'
  import IconRoundGraph from '@/components/icons/IconRoundGraph'
  import IconVisitor from "@/components/icons/IconVisitor"

  export default {
    name: "BoardStat",


    components: {
      BaseIcon,
      IconMoney,
      IconStat,
      IconRoundGraph,
      IconVisitor,
    },


    data() {
      return {
        stats: [],
        revenue: 0,
        revenuePlan: 0,
        runRate: 0,
        visitors: 0,
      }
    },


    computed: {
      ...mapState ({
        subProfileId: state => state.user.subProfileId,
      })
    },


    created() {
      this.getTotalSales()
    },


    watch: {
      subProfileId() {
        this.getTotalSales()
      },
    },


    methods: {
      async getTotalSales() {
        try {
          this.stats = await apiStats.getTotalSales();
          this.revenue = this.stats.revenue;
          this.revenuePlan = this.stats.revenue_plan;
          this.runRate = this.stats.run_rate;
          this.visitors = this.stats.visitors;
        } catch (err) {
          console.log(err)
        }
      },
      format(number) {
        return format(number)
      }
    }
  }
</script>

<style scoped lang="less">
	.stat {
		display: flex;
		flex-wrap: wrap;
		margin: -16px;

		&__item {
			padding: 16px;
		}
	}

	.stat-block {
		padding: 24px;
		border-radius: 24px;

		&--revenue { background-color: #FFFBEF; }
		&--plan { background-color: #EBF0FB; }
		&--rate { background-color: #FEF8FC; }
		&--visitors { background-color: #F8F9FE; }

		&__icon {
			background-color: #fff;
			border-radius: 100%;
			width: 48px;
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;
		}

		&__value {
			font-family: 'Clash Display';
			font-weight: 500;
			font-size: 24px;
			margin-bottom: 8px;
		}

		&__name {
			font-weight: 400;
			font-size: 16px;
			color: #4D4E53;
		}
	}
</style>