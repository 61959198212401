<template>
	<div class="not-found">
		<div class="not-found__inner">
			<div class="not-found__caption">
				<img src="@/assets/img/404.svg" alt="error 404">
			</div>
			<div class="not-found__button">
				<RouterLink :to="'/'">
					<BaseButton>Home Page</BaseButton>
				</RouterLink>
			</div>
		</div>
	</div>
</template>

<script>
  import BaseButton from "@/components/BaseButton";


  export default {
    name: 'NotFoundPage',


    components: {
      BaseButton,
    }
  }
</script>

<style scoped lang="less">
	.not-found {
		width: 100%;
		height: 100%;
		background-color: #fff;
		background-image: linear-gradient(167.5deg, #FFFFFF 11.44%, #DFE6FA 38.03%, #C8E0EE 79.4%, #FDD7F0 106%);
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: url("@/assets/img/noise.png");
			opacity: .05;
		}

		&__caption {
			margin-bottom: 22px;

			& img {
				max-width: 100%;
				width: 750px;
			}
		}

		&__button {
			display: flex;
			justify-content: center;
		}
	}
</style>