<template>
	<div>
		<button
				class="button"
				type="button"
				:class="[small ? 'button--small' : '', secondary ? 'button--secondary': '']"
		>
			<span><slot /></span>
		</button>
	</div>
</template>

<script>
  export default {
    name: "BaseButton",


    props: {
      small: {
        //задаёт размер кнопки
        type: Boolean,
        default: false,
      },
      secondary: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style scoped lang="less">
	.button {
		width: 100%;
		background-color: #C0D8FF;
		height: 56px;
		font-size: 14px;
		font-weight: 700;
		border-radius: 20px;
		padding: 0 32px;
		position: relative;
		overflow: hidden;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: conic-gradient(from 235.77deg at 68.75% -95.54%, #DAF1FF -26.82deg, #97B2FF 209.51deg, #DAF1FF 333.18deg, #97B2FF 569.51deg);
			opacity: 0;
			transition: opacity .2s ease;
		}

		&:hover&:before {
			opacity: 1;
		}

		&--small {
			height: 36px;
			font-size: 12px;
			font-weight: 500;
		}

		&--secondary {
			background-color: #F7F9FB;

			&:before {
				background: conic-gradient(from 235.77deg at 68.75% -95.54%, #F7F9FB -26.82deg, #d9dbdd 209.51deg, #F7F9FB 333.18deg, #d9dbdd 569.51deg);
			}
		}

		& span {
			position: relative;
		}
	}
</style>