<template>
	<div class="circle-loader-wrap">
		<div class="circle-loader">
			<span></span>
			<span></span>
		</div>
	</div>
</template>

<script>
  export default {
    name: 'BaseCircleLoader'
  }
</script>

<style scoped lang="less">
	.circle-loader-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 150px;
	}

	.circle-loader {
		display: block;
		position: relative;
		width: 80px;
		height: 80px;

		& span {
			display: block;
			position: absolute;
			border: 4px solid #f2b5de;
			opacity: 1;
			border-radius: 50%;
			animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
		}

		& span:nth-child(2) {
			animation-delay: -0.5s;
		}
	}

	@keyframes ripple {
		0% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 0;
		}
		4.9% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 0;
		}
		5% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 1;
		}
		100% {
			top: 0;
			left: 0;
			width: 72px;
			height: 72px;
			opacity: 0;
		}
	}

</style>