<template>

	<div class="app-main">
		<div class="app-main__left-sidebar">
			<LeftSidebar />
		</div>
		<scrollbar class="app-main__page-wrap">
			<div class="app-main__header">
				<TheHeader />
			</div>
			<div class="app-main__body">
				<div class="app-main__page">
					<RouterView />
				</div>
				<div class="app-main__right-sidebar">
					<RightSidebar />
				</div>
			</div>
		</scrollbar>
		<div class="layout" :class="layout ? 'is-active' : ''"></div>

		<MoreMenu />
		<BottomBar />
		<LogoutMenu />
		<PopupSwitchProfile />

	</div>

</template>

<script>
  import { mapState } from 'vuex'
  import Scrollbar from 'vue3-smooth-scrollbar';
  import TheHeader from '@/components/TheHeader';
  import LeftSidebar from '@/components/LeftSidebar/LeftSidebar';
  import RightSidebar from '@/components/RightSidebar/RightSidebar';
  import BottomBar from '@/components/BottomBar/BottomBar';
  import MoreMenu from '@/components/MoreMenu';
  import LogoutMenu from '@/components/LogoutMenu';
  import PopupSwitchProfile from '@/components/PopupSwitchProfile';

  export default {
    name: 'AppMain',


    components: {
      PopupSwitchProfile,
      Scrollbar,
      TheHeader,
      RightSidebar,
      LeftSidebar,
      MoreMenu,
      BottomBar,
      LogoutMenu,
    },


    data() {
      return {
        bellSetInterval: null,
      }
    },


    computed: {
      ...mapState({
        user: state => state.user,
        layout: state => state.mainView.layout,
      })
    },


    created() {
      this.setStorageData();
      this.getUserData();
      // this.getBellNotifications()
    },


    mounted() {
      // this.bellSetInterval = setInterval(() => this.getBellNotifications(), 2000)
    },


    unmounted() {
      clearInterval(this.bellSetInterval)
    },


    methods: {
      setStorageData() {
        const authenticated = {
          token: localStorage.getItem('access_token'),
          authenticated: localStorage.getItem('authenticated'),
          subProfileId: localStorage.getItem('subProfileId'),
        };
        this.$store.dispatch('user/setStorageData', authenticated)
      },
      getUserData() {
        this.$store.dispatch('user/getUserData')
      },
      async getBellNotifications() {
        this.$store.dispatch('notifications/getBellNotifications')
      },
    }
  }
</script>

<style scoped lang="less">
	@import "@/assets/style/variables.less";

	.app-main {
		height: 100%;
		padding-bottom: 68px;

		@media @desktop {
			display: flex;
			padding-bottom: unset;
		}

		&__left-sidebar {
			display: none;
			flex: 0 0 248px;

			@media @desktop {
				display: block;
			}
		}

		&__page-wrap {
			flex: 1 1 auto;
			height: 100%;
			overflow: auto;
		}

		&__header {

		}

		&__body {

			@media @desktop {
				display: flex;
			}
		}

		&__page {
			flex: 1 1 auto;
			padding: 0 20px 34px 20px;

			@media @desktop {
				padding: 0 32px 40px;
			}
		}

		&__right-sidebar {
			flex: 0 0 296px;
			display: none;
			padding-right: 40px;
			padding-bottom: 40px;

			@media @desktop {
				display: block;
			}
		}
	}

	.layout {
		background-color: rgba(0, 0, 0, 0.7);
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		transition: opacity .2s ease;
		z-index: 1;
		opacity: 0;
		pointer-events: none;

		&.is-active {
			opacity: 1;
			pointer-events: auto;
		}
	}
</style>