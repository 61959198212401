<template>
	<div
			class="notification"
			:class="[data.date_end ? 'notification--done' : 'notification--open', 'notification--' + data.type_message, data.is_read ? '' : 'notification--new']"
	>
		<div class="notification__icon">
			<img v-if="data.type_message == 'notification'" src="@/assets/icon/bell-fill-white.svg" alt="notification">
			<img v-if="data.type_message == 'request'" src="@/assets/icon/request-fill-white.svg" alt="request">
			<img v-if="data.type_message == 'weekly'" src="@/assets/icon/marker-message.svg" alt="weekly">
		</div>
		<div class="notification__body">
			<div class="notification__title">{{data.title}}</div>
			<div class="notification__inf">
				<div class="notification__type">
					<template v-if="data.type_message == 'notification'">Notification</template>
					<template v-if="data.type_message == 'request'">Request</template>
					<template v-if="data.type_message == 'weekly'">Weekly message</template>
				</div>
				<div class="notification__created-date" v-if="data.created_at"><span>●</span>{{ formatDate(data.created_at) }}</div>
			</div>
			<div class="notification__date" v-if="data.type_message === 'request'">
				<div class="notification__start">{{ formatDate(data.request_start_date) }}</div>
				<div  class="notification__date-separator">-</div>
				<template v-if="data.request_end_date">
					<div class="notification__end">{{ formatDate(data.request_end_date) }}</div>
				</template>
				<div v-else class="notification__end">
					<div class="notification__label">waiting</div>
				</div>
			</div>
			<div class="notification__content">
				<div class="notification__preview" v-if="data.full_preview_image_url">
					<img :src="data.full_preview_image_url" :alt="data.title">
				</div>
				<div class="notification__text">{{data.preview}}</div>
			</div>
		</div>
	</div>
</template>

<script>
  import formatDate from '@/services/format-date'

  export default {
    name: 'StyleFull',


    props: {
      data: {
        type: Object,
        default: null,
      }
    },


    methods: {
      formatDate(value) {
        return formatDate(value)
      },
    }
  }
</script>

<style scoped lang="less">
	@import "@/assets/style/variables.less";

	.notification {
		padding: 24px;
		display: flex;
		flex-direction: column;
		gap: 12px;
		transition: background-color .2s ease;
		cursor: pointer;

		&:hover {
			background-color: #f4f4f5;
		}

		&--new {
			background-color: #FAFAFB;
		}

		@media @desktop {
			flex-direction: row;
		}

		&__icon {
			width: 40px;
			height: 40px;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 0 0 auto;

			.notification--notification & { background-color: #6F7498; }
			.notification--request & { background-color: #C781CB; }
			.notification--weekly & { background-color: #6B7FCF; }
		}

		&__body {
			flex: 1 1 auto;
		}

		&__title {
			font-weight: 700;
			font-size: 16px;
			margin-bottom: 6px;
			padding-right: 24px;
			position: relative;

			.notification--new &:before,
			.notification--new &:after {
				content: '';
				position: absolute;
				right: 0;
				top: 4px;
				width: 10px;
				height: 10px;
				background-color: #D85041;
				border-radius: 100%;
			}

			.notification--new &:before {
				opacity: .2;
				width: 14px;
				height: 14px;
				top: 2px;
				right: -2px;
			}

			.notification--new &:after {

			}
		}

		&__inf {
			display: flex;
			align-items: center;
			margin-bottom: 8px;
		}

		&__type {
			font-size: 12px;
			font-weight: 700;

			.notification--notification & { color: #6F7498; }
			.notification--request & { color: #AC4389; }
			.notification--weekly & { color: #5968B1; }
		}

		&__created-date {
			color: #ABAEBA;
			font-weight: 500;
			font-size: 12px;
			line-height: 16px;

			& span {
				margin: 0 12px;
				font-size: 10px;
			}
		}

		&__date {
			display: flex;
			align-items: center;
			flex: 1 1 auto;
			margin-bottom: 8px;
		}

		&__start {
			font-size: 14px;
			font-weight: 500;

			.notification--open & {
				/*flex: 1 1 auto;*/
			}
		}

		&__date-separator {
			padding: 0 4px;
		}

		&__end {
			font-size: 14px;
			font-weight: 500;
		}

		&__label {
			background-color: #FFF6E7;
			color: #C58F34;
			font-size: 12px;
			font-weight: 700;
			height: 28px;
			padding: 0 12px;
			display: flex;
			align-items: center;
			border-radius: 8px;
		}

		&__content {
			display: flex;
			gap: 16px;
		}

		&__preview {
			flex: 0 0 auto;
			width: 80px;
			height: 80px;
			border-radius: 16px;
			overflow: hidden;

			& img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&__text {
			font-size: 12px;
			line-height: 1.5;
		}
	}
</style>