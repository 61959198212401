<template>
	<svg
			xmlns="http://www.w3.org/2000/svg"
			:width="size"
			:height="size"
			viewBox="0 0 20 20"
			:aria-labelledby="name"
			role="presentation"
	>
		<title :id="name"> {{ name }} icon </title>
		<g :fill="color">
			<slot/>
		</g>
	</svg>
</template>

<script>
  export default {
    name: 'BaseIcon',


    props: {
      name: {
        type: String,
        default: 'icon'
      },
      size: {
        type: [Number, String],
        default: 18
      },
      color: {
        type: String,
        default: 'currentColor'
      }
    }
  }
</script>

<style scoped>

</style>