<template>
	<div class="bottom-bar">
		<div class="bottom-bar__inner">
			<div class="bottom-bar__button">
				<RouterLink :to="'/dashboard'" :class="$route.meta.title === 'Dashboard' ? 'router-link-active' : ''" @click="closeMoreMenu">
					<div class="nav-button">
						<div class="nav-button__icon">
							<img src="@/assets/icon/dashboard-outline.svg" alt="dashboard">
							<img src="@/assets/icon/dashboard-fill-black.svg" alt="dashboard">
						</div>
						<div class="nav-button__caption">Dashboard</div>
					</div>
				</RouterLink>
			</div>
			<div class="bottom-bar__button">
				<RouterLink :to="'/my-team'" :class="$route.path === '/my-team' ? 'router-link-active' : ''" @click="closeMoreMenu">
					<div class="nav-button">
						<div class="nav-button__icon">
							<img src="@/assets/icon/people-outline-black.svg" alt="my team">
							<img src="@/assets/icon/people-outline-black.svg" alt="my team">
<!--							<img src="@/assets/icon/request-fill-black.svg" alt="dashboard">-->
						</div>
						<div class="nav-button__caption">My Team</div>
					</div>
				</RouterLink>
			</div>
			<div class="bottom-bar__button">
				<RouterLink :to="'/monthly-activity'" :class="$route.path === '/monthly-activity' ? 'router-link-active' : ''" @click="closeMoreMenu">
					<div class="nav-button">
						<div class="nav-button__icon">
							<img src="@/assets/icon/calendar-outline-black.svg" alt="monthly activity">
							<img src="@/assets/icon/calendar-outline-black.svg" alt="monthly activity">
<!--							<img src="@/assets/icon/request-fill-black.svg" alt="dashboard">-->
						</div>
						<div class="nav-button__caption">Monthly activity</div>
					</div>
				</RouterLink>
			</div>
<!--			<div class="bottom-bar__button">-->
<!--				<RouterLink :to="{ name: 'Inbox', params: { type: 'request' }}" :class="$route.path === '/inbox/request' ? 'router-link-active' : ''" @click="closeMoreMenu">-->
<!--					<div class="nav-button">-->
<!--						<div class="nav-button__icon">-->
<!--							<img src="@/assets/icon/request-outline.svg" alt="dashboard">-->
<!--							<img src="@/assets/icon/request-fill-black.svg" alt="dashboard">-->
<!--						</div>-->
<!--						<div class="nav-button__caption">Requests</div>-->
<!--					</div>-->
<!--				</RouterLink>-->
<!--			</div>-->
<!--			<div class="bottom-bar__button">-->
<!--				<RouterLink :to="{ name: 'Inbox', params: { type: 'weekly' }}" :class="$route.path === '/inbox/message' ? 'router-link-active' : ''"  @click="closeMoreMenu">-->
<!--					<div class="nav-button">-->
<!--						<div class="nav-button__icon">-->
<!--							<img src="@/assets/icon/mail-outline-gray.svg" alt="message">-->
<!--							<img src="@/assets/icon/mail-fill-black.svg" alt="message">-->
<!--						</div>-->
<!--						<div class="nav-button__caption">Weekly message</div>-->
<!--					</div>-->
<!--				</RouterLink>-->
<!--			</div>-->
			<div class="bottom-bar__button">
				<div class="nav-button" @click="toggleMoreMenu" :class="moreMenu ? 'router-link-active' : ''">
					<div class="nav-button__icon">
						<img src="@/assets/icon/more-outline.svg" alt="more">
						<img src="@/assets/icon/more-fill-black.svg" alt="more">
					</div>
					<div class="nav-button__caption">More</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import { mapState } from 'vuex'
  import {OPEN_MORE_MENU} from "@/store/modules/mainView/constants";
  import {CHANGE_PROFILE} from "@/store/modules/mainView/constants";

  export default {
    name: 'BottomBar',


    computed: {
      ...mapState({
        moreMenu: state => state.mainView.moreMenu,
      }),
    },


    methods: {
      toggleMoreMenu() {
        this.$store.commit(`mainView/${CHANGE_PROFILE}`, false);
        if (this.$store.state.mainView.moreMenu) {
          this.$store.commit(`mainView/${OPEN_MORE_MENU}`, false)
        } else {
          this.$store.commit(`mainView/${OPEN_MORE_MENU}`, true)
        }
      },
      closeMoreMenu() {
        this.$store.commit(`mainView/${OPEN_MORE_MENU}`, false)
      },
    },
  }
</script>

<style scoped lang="less">
	@import "@/assets/style/variables.less";

	.bottom-bar {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 68px;
		z-index: 4;
		background-color: #F7F9FB;
		border-radius: 22px 22px 0 0;
		display: flex;
		justify-content: center;

		@media @desktop {
			display: none;
		}

		&__inner {
			display: flex;
			max-width: 336px;
			width: 100%;
		}

		&__button {
			flex: 1 1 100%;
		}
	}



	.nav-button {
		padding: 12px 4px;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;

		&__icon {
			display: flex;
			margin-bottom: 8px;
			width: 24px;
			height: 24px;
			position: relative;

			& img {
				position: absolute;
				object-fit: contain;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: block;
				transition: opacity .2s ease;

				&:nth-child(1) {
					opacity: 1;

					.router-link-active & {
						opacity: 0;
					}
				}

				&:nth-child(2) {
					opacity: 0;

					.router-link-active & {
						opacity: 1;
					}
				}
			}
		}

		&__caption {
			text-align: center;
			white-space: nowrap;
			font-weight: 500;
			font-size: 10px;
			color: #4D4E53;

			.router-link-active & {
				color: @color-text;
			}
		}
	}
</style>