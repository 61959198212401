<template>
	<div class="board" v-if="statsLoaded">
		<h2 class="board__title h5">Sales Statistics</h2>
		<div class="board__body board__body--thin-padding">
			<Bar :data="chartData" :options="chartOptions" :height="230" />
		</div>
	</div>
	<div class="sales-statistics">

	</div>
</template>

<script>
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

  export default {
    name: 'BoardSalesStatistics',


    components: {
      Bar,
    },


    props: {
      stats: {
        type: Array,
        default: () => ([]),
        required: true,
      },
    },


    data() {
      return {
        statsLoaded: false,
        chartData: {},
        chartOptions: {},
      }
    },


    created() {
      if (this.statsLoaded) {
        this.setChartData()
      }
    },


    watch: {
      stats(value) {
        if (value) {
          this.statsLoaded = true;
          this.setChartData()
        }
      }
    },


    methods: {
      ucFirst(str) {
        if (!str) return str;
        return str[0].toUpperCase() + str.slice(1);
      },
      setChartData() {
        let barLabels = [];
        let barColors = [];
        let barData = [];
        this.stats.forEach((item, index) => {
          barLabels[index] = this.ucFirst(item.month);
          barColors[index] = item.active ? '#C0D8FF' : '#E4E7EB';
          barData[index] = item.total_sales
        });

        this.chartData = {
          labels: barLabels,
            datasets: [
            {
              label: 'Sales',
              barPercentage: 0.5,
              backgroundColor: barColors,
              data: barData,
              borderRadius: 8,
              borderSkipped: false,
              maxBarThickness: 16,
            }
          ],
        };

        this.chartOptions = {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
              ticks: {
                font: { size: 14, family: 'Jost', weight: 500, },
                color: '#17181C',
              }
            },
            y: {
              border: {
                dash: [12, 8],
                  display: false,
              },
              ticks: {
                font: { size: 12, family: 'Jost' },
                color: '#17181C',
                  align: 'end',
                  count: 4,
                  labelOffset: -5,
                  callback: function(value) {
                  return Math.round(value/1000) + 'k';
                }
              }
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        }
      }
    }
  }
</script>

<style scoped lang="less">

</style>