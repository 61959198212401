<template>
	<div v-if="switchProfile" class="popup" style="width: 300px">
		<div @click="toggleModalSwitchProfile" class="popup__close">
			<img src="@/assets/icon/close.svg" alt="toggle">
		</div>
		<div class="popup__title h5">Switch profile</div>
		<div class="popup__body">
			<ProfilesList />
		</div>
	</div>
</template>

<script>
  import { mapState } from 'vuex'
  import ProfilesList from '@/components/ProfilesList';
  import {CHANGE_PROFILE} from '@/store/modules/mainView/constants';

  export default {
    name: 'popupSwitchProfile',


    components: {
      ProfilesList
    },


    computed: {
      ...mapState({
        switchProfile: state => state.mainView.switchProfile,
      })
    },


    methods: {
      toggleModalSwitchProfile() {
        if (this.$store.state.mainView.switchProfile) {
          this.$store.commit(`mainView/${CHANGE_PROFILE}`, false)
        } else {
          this.$store.commit(`mainView/${CHANGE_PROFILE}`, true)
        }
      },
    }
  }
</script>

<style scoped lang="less">

</style>