import { createStore } from 'vuex'
import router from '@/router'
// import VuexPersistence from 'vuex-persist'

import mainView from './modules/mainView'
import user from './modules/user'
import notifications from './modules/notifications'
import stats from "./modules/stats";

const actions = {
  resetState({ dispatch, state }) {
    // вызываем в каждом модуле экшн resetState
    Object.keys(state).forEach(key => {
      dispatch(`${key}/resetState`)
    });
    router.push({ name: 'Auth' })
  },
};

// const vuexLocal = new VuexPersistence ({
//   storage: window.localStorage,
//   // модули, данные которых будут сохраняться в local storage
//   modules: ['user']
// })

const store = createStore({
  actions,
  modules: {
    mainView,
    user,
    notifications,
    stats,
  },
  // plugins: [vuexLocal.plugin]
});

export default store