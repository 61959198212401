import axios from "@/services/axios-instance";

export default {
  async authorize({ email, password }) {
    const response = await axios.post('/account/token/login/', {
      username: email,
      password: password
    })
    return response.data
  },

  async getUserData() {
    const response = await axios.get('/account/me/')
    return response.data
  },
}
