<template>
	<div class="input-frame">
		<label
				:class="[prependIcon ? 'input-wrap--prepend-icon' : '', appendIcon ? 'input-wrap--append-icon' : '']"
				class="input-wrap"
				ref="input">
			<input
					class="input"
					:type="type"
					:value="modelValue"
					@input="$emit('update:modelValue', $event.target.value)"
					placeholder="modelValue"
					autocomplete="on"
			/>
			<span class="input-wrap__placeholder">{{ label }}</span>
			<img class="input-wrap__prepend-icon" v-if="prependIcon" :src="require(`@/assets/icon/${prependIcon}.svg`)" :alt="prependIcon">
			<img class="input-wrap__append-icon" v-if="appendIcon" :src="require(`@/assets/icon/${appendIcon}.svg`)" :alt="appendIcon" @click="$emit('append')">
		</label>
		<div v-if="errorMessage.length > 0" class="input-frame__error">{{ errorMessage }}</div>
	</div>
</template>

<script>
  export default {
    name: 'BaseInput',


    props: {
      label: {
        type: String,
      },
      type: {
        type: String,
        default: 'text'
      },
      modelValue: {
        type: String,
        default: null,
      },
      prependIcon: {
        type: String
      },
      appendIcon: {
        type: String
      },
      errorMessage: {
        type: String
      },
    },


    watch: {
      errorMessage() {
        this.setError()
      },
    },


    methods: {
      setError() {
        this.$refs.input.classList.remove('error');
        if (this.errorMessage.length > 0) {
          this.$refs.input.classList.add('error')
        }
      },
    }
  }
</script>

<style scoped lang="less">
	@import "@/assets/style/variables.less";

	.input-frame {

		&__error {
			color: #D85041;
			font-size: 12px;
			margin-top: 8px;
		}
	}

	.input-wrap {
		position: relative;
		display: block;

		&.error {
			animation: shake 0.7s cubic-bezier(.36,.07,.19,.97);
		}

		&__placeholder {
			position: absolute;
			top: 0;
			left: 20px;
			color: #94959F;
			font-size: 14px;
			line-height: 64px;
			transition: font-size .2s ease, line-height .2s ease;
			pointer-events: none;

			.input-wrap.error & {
				color: #D85041;
			}

			.input-wrap--prepend-icon & {
				left: 52px;
			}

			.input-wrap--append-icon & {

			}
		}

		& .input {
			width: 100%;
			height: 64px;
			padding: 28px 20px 12px 20px;
			border-radius: 16px;
			background-color: #F9FAFB;
			font-size: 14px;
			line-height: 24px;
			font-weight: 400;

			&::placeholder { color: transparent; }
		}

		&--prepend-icon .input {
			padding-left: 52px;
		}

		&--append-icon .input {
			padding-right: 52px;
		}

		&.error .input {
			color: #D85041;
		}

		& .input:focus + &__placeholder,
		& .textarea:focus + &__placeholder {
			font-size: 12px;
			line-height: 32px;
		}

		& .input:not(:placeholder-shown) + &__placeholder,
		& .textarea:not(:placeholder-shown) + &__placeholder {
			font-size: 12px;
			line-height: 32px;
		}

		&__prepend-icon,
		&__append-icon {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 20px;
			display: block;
		}

		&__prepend-icon {
			left: 20px;
		}

		&__append-icon {
			right: 20px;
		}
	}

	@keyframes shake {
		10%, 90% {
			transform: translate3d(-1px, 0, 0);
		}

		20%, 80% {
			transform: translate3d(2px, 0, 0);
		}

		30%, 50%, 70% {
			transform: translate3d(-4px, 0, 0);
		}

		40%, 60% {
			transform: translate3d(4px, 0, 0);
		}
	}
</style>