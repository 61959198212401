<template>
	<div
			class="notification"
			:class="data.request_end_date ? 'notification--done' : 'notification--open'"
	>
		<div class="notification__status">
			<span v-if="data.request_end_date">✓</span>
			<span v-else>!</span>
		</div>
		<div v-if="data.request_start_date" class="notification__date">
			<div class="notification__start">{{ formatDate(data.request_start_date) }}</div>
			<div  class="notification__date-separator">-</div>
			<div v-if="data.request_end_date" class="notification__end">{{ formatDate(data.request_end_date) }}</div>
			<div v-else class="notification__end">
				<div class="notification__label">waiting</div>
			</div>
		</div>
	</div>
</template>

<script>
  import formatDate from '@/services/format-date'

  export default {
    name: 'StyleShort',


    props: {
      data: {
        type: Object,
        default: null,
      }
    },


    methods: {
      formatDate(value) {
        return formatDate(value)
      },
    }
  }
</script>

<style scoped lang="less">
	.notification {
		display: flex;
		align-items: center;
		padding: 12px 8px;
		background-color: #FCFCFD;
		border-radius: 14px;

		&__status {
			padding: 4px 0;
			margin-right: 14px;

			& span {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 20px;
				height: 20px;
				border-radius: 100%;
				font-weight: 700;

				.notification--open & {
					background-color: rgba(216, 80, 65, 0.4);
					color: #C25144;
					font-size: 14px;
				}

				.notification--done & {
					background-color: rgba(185, 221, 121, 0.4);
					color: #93BC48;
					font-size: 12px;
				}
			}
		}

		&__date {
			display: flex;
			align-items: center;
			flex: 1 1 auto;
			justify-content: flex-start;
		}

		&__start {
			font-size: 14px;
			font-weight: 500;
		}

		&__date-separator {
			padding: 0 4px;
		}

		&__end {
			font-size: 14px;
			font-weight: 500;
		}

		&__label {
			background-color: #FFF6E7;
			color: #C58F34;
			font-size: 12px;
			font-weight: 700;
			height: 28px;
			padding: 0 12px;
			display: flex;
			align-items: center;
			border-radius: 8px;
		}
	}
</style>