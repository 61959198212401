// import axios from '@/services/axios-instance'
import api from '@/api/user'
import {
  SET_SUB_PROFILE_ID_FROM_STORAGE,
  SET_FROM_STORAGE,
  SET_AUTH,
  SET_USER_DATA,
  USER_LOADING,
  USER_LOADED,
  REMOVE_USER,
} from './constants'
import { RESET_STATE } from '@/store/constants'
import resetState from '@/store/helpers/resetState'

export const initialState = {
  info: {},
  token: '',
  authenticated: false,
  subProfileId: '',
  userLoading: false,
  userLoaded: false,
};

const mutations = {
  [SET_SUB_PROFILE_ID_FROM_STORAGE](state, data) {
    state.subProfileId = data
  },
  [SET_FROM_STORAGE](state, data) {
    state.token = data.token;
    state.subProfileId = data.subProfileId;
    if (data.authenticated === 'true') {
      state.authenticated = true
    } else {
      state.authenticated = false
    }
  },
  [SET_AUTH](state, data) {
    localStorage.setItem('access_token', data.access);
    localStorage.setItem('refresh_token', data.refresh);
    localStorage.setItem('subProfileId', '');
    localStorage.setItem('authenticated', true);
    state.token = data.access;
    state.authenticated = true
  },
  [SET_USER_DATA](state, data) {
    state.info = data
  },
  [USER_LOADING](state, value) {
    state.userLoading = value
  },
  [USER_LOADED](state, value) {
    state.userLoaded = value
  },
  [REMOVE_USER](state) {
    localStorage.setItem('access_token', '');
    localStorage.setItem('refresh_token', '');
    localStorage.setItem('subProfileId', '');
    localStorage.setItem('authenticated', false);
    state.token = '';
    state.authenticated = false;
    // delete axios.headers.authorization;
  },
  [RESET_STATE](state) {
    resetState(state, initialState)
  },
};

const actions = {
  setStorageData({commit}, data) {
    commit(SET_FROM_STORAGE, data)
  },
  async authorize({commit}, {email, password}) {
    const data = await api.authorize({email, password});
    commit(SET_AUTH, data);
    return data
  },
  async getUserData({commit}) {
    commit(USER_LOADING, true);
    try {
      const data = await api.getUserData();
      commit(SET_USER_DATA, data)
    } catch (err) {
      console.log('get user error: ', err)
    } finally {
      commit(USER_LOADING, false);
      commit(USER_LOADED, true)
    }
  },
  resetState({commit}) {
    commit(REMOVE_USER);
    commit(RESET_STATE)
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
}