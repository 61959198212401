<template>
<!--	разделение по компонентам должно происходить на основне типов а не стилей сообщения-->
	<StyleShort v-if="short" :data="data" />
	<StyleAccordion v-else-if="accordion" :data="data" />
	<StyleFull v-else :data="data" />
</template>

<script>
  import StyleAccordion from "@/components/BaseNotification/components/StyleAccordion";
  import StyleShort from "@/components/BaseNotification/components/StyleShort";
  import StyleFull from "@/components/BaseNotification/components/StyleFull";

  export default {
    name: "BaseNotification",


    components: {
      StyleFull,
      StyleAccordion,
      StyleShort,
    },


    props: {
      data: {
        type: Object,
        default: null,
      },
      accordion: {
        //задаёт стиль блока аккордеон
        type: Boolean,
        default: false,
      },
      short: {
        //задаёт упрощённый стиль блока
        type: Boolean,
        default: false,
      },
    }
  }
</script>

<style scoped>

</style>