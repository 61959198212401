import axios from "@/services/axios-instance"

export default {
  async getTotalSales() {
    const response = await axios.get('/dashboards/total-info-header/');
    return response.data
  },

  async getMonthlyActivity() {
    const response = await axios.get('/dashboards/monthly-activity/');
    return response.data
  },

  async getContentStats() {
    const response = await axios.get('/notifications/content-statistics/');
    return response.data
  },

  async getStatisticsSales(params) {
    const response = await axios.get('/dashboards/statistics-sales/', {params});
    return response.data
  },

  async getLastOrders() {
    const response = await axios.get('/dashboards/last-orders/');
    return response.data
  },

  async getFansCategories() {
    const response = await axios.get('/dashboards/fans-categories/');
    return response.data
  }
}