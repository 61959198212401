<template>
	<header class="header">
		<div class="header__bg">
			<div class="header__logo">
				<RouterLink :to="'/'"><img src="@/assets/img/sparks-logo.png" alt="sparks-logo"></RouterLink>
			</div>
<!--			<div class="header__notification">-->
<!--				<RouterLink :to="'/notifications'" class="bell-link">-->
<!--					<div class="bell">-->
<!--						<img src="@/assets/icon/bell-outline-black.svg" alt="notification">-->
<!--						<span></span>-->
<!--					</div>-->
<!--				</RouterLink>-->
<!--				<div class="bell-wrap">-->
<!--					<div class="bell" :class="newNotifications ? 'new' : ''">-->
<!--						<img src="@/assets/icon/bell-outline-black.svg" alt="notification">-->
<!--						<span></span>-->
<!--					</div>-->
<!--				</div>-->
<!--				<NotificationDropdown />-->
<!--			</div>-->
		</div>
		<div class="header__page-title">
			<h1 class="h3">{{this.$route.meta.title}}</h1>
		</div>
	</header>
</template>

<script>
  import { mapState } from 'vuex'
  // import NotificationDropdown from "@/components/NotificationDropdown";

  export default {
    name: 'AppHeader',


    components: {
      // NotificationDropdown
    },


    computed: {
      ...mapState({
        countNew: state => state.notifications.countNew,
      }),
      newNotifications() {
        return (this.countNew > 0)
      }
    },
  }
</script>

<style scoped lang="less">
	@import "@/assets/style/variables.less";

	.header {

		@media @desktop {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 30px 40px 30px 40px;
		}

		&__bg {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #F7F9FB;
			padding: 12px 20px;

			@media @desktop {
				padding: unset;
				background-color: unset;
				order: 2;
			}
		}

		&__logo {

			& img {
				display: block;
				width: 145px;
			}

			@media @desktop {
				display: none;
			}
		}

		&__notification {
			position: relative;
		}

		&__page-title {
			padding: 24px 20px;

			@media @desktop {
				order: 1;
				padding: unset;
			}
		}
	}

	.bell {
		width: 52px;
		height: 52px;
		border: 1px solid #ECEAEA;
		border-radius: 50%;
		padding: 4px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: border-color .2s ease;

		@media @desktop {
			.header__notification:hover & {
				border: 1px solid #97B2FF;
			}
		}

		&.new {
			border-radius: 50% 0 50% 50%;
		}

		&::before {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			width: 44px;
			height: 44px;
			background-color: #fff;
			transform: translate(-50%, -50%);
			border-radius: 100%;
			transition: background-color .2s ease;

			@media @desktop {
				background-color: #F7F9FB;

				.header__notification:hover & {
					background-color: #EBF0FB;
				}
			}
		}

		& img {
			display: block;
			position: relative;
			width: 24px;
		}

		& span {
			position: absolute;
			top: -8px;
			right: -8px;
			background-color: #D85041;
			border-radius: 100%;
			width: 18px;
			height: 18px;
			border: 4px solid #fff;
			display: none;
		}

		&.new span {
			display: block;
		}
	}

	.bell-link {

		@media @desktop {
			display: none;
		}
	}

	.bell-wrap {
		display: none;

		@media @desktop {
			display: block;
		}
	}
</style>